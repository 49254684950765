import axios from 'axios';
import _ from 'lodash';
import bugsnagClient from '../bugsnag-client';
import NetworkError from '../errors/network-error';

export const instance = axios.create({ timeout: 60000 });

instance.interceptors.request.use((config) => {
  const { content: token } = document.head.querySelector('meta[name=csrf-token]');
  config.headers['CSRF-Token'] = token; // eslint-disable-line no-param-reassign
  return config;
}, error => error);

instance.interceptors.response.use(response => response, (error) => {
  const status = _.get(error, 'response.status');
  if (!status) {
    throw new NetworkError();
  }

  bugsnagClient.notify(error, {
    metaData: {
      status,
      url: _.get(error, 'config.url')
    },
    severity: 'warning'
  });
  throw error;
});

export const submitSignupForm = async (values) => {
  const { data } = await instance.post('/api/provision', values);
  try {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'signup' });
    }
  } catch (e) { /** left intentionally empty */ }
  return data;
};

export const submitPipedriveSignupForm = async (values) => {
  const { data } = await instance.post('/api/provision/pipedrive', values);
  try {
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'signup' });
    }
  } catch (e) { /** left intentionally empty */ }
  return data;
};

/**
 * Maps the form fields to a Hubpost form API fields.
 * @see https://legacydocs.hubspot.com/docs/methods/forms/submit_form
 * @param {{firstName: string, lastName: string, email: string}} values posted form fields.
 */
const mapToHubspotContactFields = values => ([
  {
    name: 'firstname',
    value: values.firstName
  },
  {
    name: 'lastname',
    value: values.lastName
  },
  {
    name: 'email',
    value: values.email
  },
  {
    name: 'message',
    value: `Created from trial form. Company: ${values.company}, slug: ${values.slug}`
  }
]);

/**
 * Creates the context part for Hubspot form API payload.
 * @param {string} hutk
 */
const mapToHubspotContextFields = hutk => ({
  hutk
});

/**
 * Posts a form data to Hubspot via HubSpot form Api
 * @see https://legacydocs.hubspot.com/docs/methods/forms/submit_form
 * @param {{formGuid: string, values: object, hutk: string}}
 */
const postHubspotForm = async ({ formGuid, values, hutk }) => {
  const portalId = window.dearLucy.hubspotPortalId;
  const hubspotFormApiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`;
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const payload = {
    fields: mapToHubspotContactFields(values),
    context: mapToHubspotContextFields(hutk)
  };
  const response = await instance.post(hubspotFormApiUrl, payload, config);
  return response;
};

/**
 * Wrapper for creating new contact in Hubspot using postHubspotForm().
 * @param {{values: object, hutk: string}}
 */
export const postHubspotContactForm = async ({ values, hutk }) => {
  const formGuid = window.dearLucy.hubspotTrialFormId;
  const res = await postHubspotForm({ formGuid, values, hutk });
  return res;
};
