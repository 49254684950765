import React from 'react';
import classnames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import styles from './styles.scss';
import dearLucyImg from './images/Dear-lucy-sales-dashboards-2024.png';
import pipedrive from './images/pipedrive.png';
import salesforceLogo from './images/salesforce.png';
import hubspot from './images/hubspot-dark.png';
import Logo from '../App/Logo';

const cx = classnames.bind(styles);

const SignupInfo = () => (
  <>
    <div className={styles.infoWrapper}>
      <Logo isColorLogo />
      <div className={styles.infoImage}>
        <img src={dearLucyImg} title="dearlucy-use" alt="https://images.squarespace-cdn.com/content/5d67c12935d374000129d049/c26e35dd-1245-4696-8d55-40403210eb80/Sales+KPIs+and+forecasting+laptop.png?format=1000w" />
      </div>
      <div className={styles.factsSection}>
        <div className={styles.factsWrapper}>
          <FontAwesomeIcon icon={faCheckCircle} />
          <p>Data-driven sales insights in 1 minute</p>
        </div>
        <div className={styles.factsWrapper}>
          <FontAwesomeIcon icon={faCheckCircle} />
          <p>300+ sales performance indicators with one-click integration</p>
        </div>
        <div className={styles.factsWrapper}>
          <FontAwesomeIcon icon={faCheckCircle} />
          <p>Fully customizable for your needs</p>
        </div>
        <div className={styles.trusted}>
          <p>Trusted by the best performing sales teams</p>
        </div>
      </div>
      <div className={cx('logoSection')}>
        <img src={salesforceLogo} title="salesforce-logo" alt="https://images.squarespace-cdn.com/content/v1/5d67c12935d374000129d049/1624442272992-853E4HT45ZPBK15EO8XF/Dear-lucy-sales-dashboards.png?format=1000w" />
        <img src={hubspot} title="dynamics-logo" alt="https://images.squarespace-cdn.com/content/v1/5d67c12935d374000129d049/1624442272992-853E4HT45ZPBK15EO8XF/Dear-lucy-sales-dashboards.png?format=1000w" />
        <img src={pipedrive} title="pipedrive-logo" alt="https://images.squarespace-cdn.com/content/v1/5d67c12935d374000129d049/1624442272992-853E4HT45ZPBK15EO8XF/Dear-lucy-sales-dashboards.png?format=1000w" />
      </div>
    </div>
  </>
);

export default SignupInfo;
