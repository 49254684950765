import querystring from 'query-string';

export function getAdminIntegrationRedirectUrl(slug, serviceConfigurationId) {
  const { adminAppUrl } = window.dearLucy;
  return `${adminAppUrl}/${slug}/integrations/${serviceConfigurationId}`;
}

export function getAdminRedirectUrl(slug) {
  const { adminAppUrl } = window.dearLucy;
  return `${adminAppUrl}/${slug}/integrations/new`;
}

export function getMarketplaceLoginUrl(pathname, params) {
  const { loginAppUrl } = window.dearLucy;
  const query = querystring.stringify(params);
  return `${loginAppUrl}/marketplace${pathname}?${query}`;
}
