// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".disclaimer___1L6qP{order:10;text-align:center}\n", "",{"version":3,"sources":["/app/client/app/components/Disclaimer/styles.scss"],"names":[],"mappings":"AAAA,oBACE,QAAS,CACT,iBAAkB","file":"styles.scss","sourcesContent":[".disclaimer {\n  order: 10;\n  text-align: center;\n}\n"]}]);
// Exports
exports.locals = {
	"disclaimer": "disclaimer___1L6qP"
};
module.exports = exports;
