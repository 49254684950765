import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styles from './styles.scss';

const cx = classnames.bind(styles);

const FormInput = ({
  field,
  focusOnRender,
  form,
  label,
  icon,
  ...rest
}) => {
  const { t } = useTranslation('common');
  const { touched, errors } = form;
  const id = _.uniqueId('form_field_');
  const hasError = touched[field.name] && errors[field.name];
  const focusInput = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (!focusInput.current) {
        return;
      }
      focusInput.current.focus();
    }, 0);
  }, []);
  return (
    <div className={cx(styles.formField, {
      [styles.error]: hasError
    })}
    >
      <label
        className={cx(styles.label, {
          [styles.active]: field.value,
          [styles.icon]: icon
        })}
        htmlFor={id}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        <input
          id={id}
          ref={focusOnRender ? focusInput : null}
          {...field} // eslint-disable-line react/jsx-props-no-spreading
          {...rest} // eslint-disable-line react/jsx-props-no-spreading
        />
        {label && <span className={styles.text}>{label}</span>}
      </label>
      {hasError && <div className={styles.errorMessage}>{t(errors[field.name])}</div>}
    </div>
  );
};

FormInput.defaultProps = {
  icon: null,
  focusOnRender: false,
  label: null
};

FormInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any
  }).isRequired,
  focusOnRender: PropTypes.bool,
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({})
  }).isRequired,
  label: PropTypes.string,
  icon: PropTypes.shape({})
};

export default FormInput;
