import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './styles.scss';

const Error = ({ status: { error, seconds } }) => {
  const { t } = useTranslation('common');
  const text = seconds > 0
    ? t(`error.${error}`, { seconds })
    : t(`error.${error}`);

  return (
    <div className={styles.errorMessage}>
      {text}
    </div>
  );
};

Error.propTypes = {
  status: PropTypes.shape({
    error: PropTypes.string.isRequired,
    seconds: PropTypes.number
  }).isRequired
};

export default Error;
