import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './styles.scss';

const cx = classnames.bind(styles);

const Form = (props) => {
  const { activePage, className, handleSubmit, isSubmitting, status } = props;
  return (
    <form onSubmit={handleSubmit} className={cx(styles.form, className)}>
      <fieldset disabled={isSubmitting}>
        {React.cloneElement(activePage, { parentState: { status, ...props } })}
      </fieldset>
    </form>
  );
};

Form.propTypes = {
  activePage: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  status: PropTypes.shape({
    error: PropTypes.string,
    nextAvailableRequestInSeconds: PropTypes.number
  })
};

Form.defaultProps = {
  className: null,
  status: {}
};

export default Form;
