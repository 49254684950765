import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import queryString from 'query-string';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import Cookies from 'js-cookie';
import * as api from '../../api';
import PageContainer from './PageContainer';
import SinglePageForms from './SinglePageForms';
import EmailFormPage from './Forms/EmailFormPage';
import NameFormPage, { SLUG_MAX_LENGTH } from './Forms/NameFormPage';
import PasswordFormPage from './Forms/PasswordFormPage';
import SlugFormPage from './Forms/SlugFormPage';
/* import PhoneFormPage from './Forms/PhoneFormPage'; */
import SignupInfo from '../SignupInfo';
import NetworkError from '../../errors/network-error';
import { getAdminRedirectUrl } from '../../url';
import styles from './styles.scss';
import Logo from '../App/Logo';
import Disclaimer from '../Disclaimer';

const cx = classnames.bind(styles);

const EXCLUDE_URL_REGEX = /^(?!.*(https|http):).*/;

const validationSchemas = Yup.object().shape({
  firstName: Yup.string()
    .required('error.required'),
  lastName: Yup.string()
    .required('error.required'),
  company: Yup.string()
    .matches(EXCLUDE_URL_REGEX, 'error.company.url')
    .required('error.required'),
  email: Yup.string()
    .email('error.email.invalidFormat')
    .required('error.required'),
  phone: Yup.string(),
  password: Yup.string()
    .min(8, 'error.password.minLength')
    .matches(/[^a-zA-Z]+/, 'error.password.complexity')
    .required('error.required'),
  slug: Yup.string()
    .matches(/^[0-9a-z-]+$/, 'error.slug.validSlug')
    .min(2, 'error.slug.minLength')
    .max(SLUG_MAX_LENGTH, 'error.slug.maxLength')
    .required('error.required')
});

const fieldToPage = {
  firstName: 0,
  lastName: 0,
  company: 0,
  email: 1,
  password: 2,
  slug: 3
};

const delay = (timeout) => {
  if (timeout === 0) {
    return Promise.resolve();
  }
  return new Promise(resolve => setTimeout(resolve, timeout));
};

const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  companyDomain: ''
};

const hubspotCookieName = 'hubspotutk';

const SignupForm = ({ location }) => {
  const params = {
    ...DEFAULT_VALUES,
    ...queryString.parse(location.search)
  };
  const { t } = useTranslation('common');
  const onSubmit = async (values, { setSubmitting, setFieldError, setStatus }) => {
    setStatus({});
    try {
      if (location.pathname.includes('pipedrive')) {
        await api.submitPipedriveSignupForm(values);
      } else {
        await api.submitSignupForm({
          ...values,
          source: location.pathname.slice(1) // remove slash from the beginning
        });
      }
      try {
        await api.postHubspotContactForm({ values, hutk: Cookies.get(hubspotCookieName) });
      } catch (e) {
        // This is empty on purpose, the interceptor will notify bugsnag if there's an exception
      }
      const { connectionStringsPollInterval } = window.dearLucy;
      await delay(connectionStringsPollInterval);
      const redirectUrl = getAdminRedirectUrl(values.slug);
      window.location.assign(redirectUrl);
    } catch (error) {
      setSubmitting(false);
      if (error instanceof NetworkError) {
        setStatus({ error: 'network' });
      } else if (error.response && error.response.status >= 500) {
        setStatus({ error: 'serviceDown' });
      } else if (error.response && error.response.status === 409) {
        const { errors = [] } = error.response.data;
        const errorPages = [];
        errors.forEach(({ field, errorCode }) => {
          const errorField = field === 'slug' ? 'company' : field;
          setFieldError(errorField, errorCode);
          errorPages.push(fieldToPage[errorField]);
        });
      } else if (error.response && error.response.status === 429) {
        const { nextValidRequestDate } = error.response.data;
        const nextAvailableRequestInSeconds = DateTime
          .fromISO(nextValidRequestDate)
          .diffNow('seconds')
          .seconds;
        setStatus({
          error: nextAvailableRequestInSeconds > 1 ? 'ratelimit_plural' : 'ratelimit',
          seconds: parseInt(nextAvailableRequestInSeconds, 10)
        });
      } else {
        setStatus({ error: 'unknown' });
      }
    }
  };
  return (
    <>
      <div className={styles.contentWrapper}>
        <SignupInfo />
        <div className={styles.formWrapper}>
          <div className={styles.logoRightSide}>
            <Logo />
          </div>
          <>
            <h1>
              {t('signup.headers.title')}
            </h1>
            <h2>
              <FontAwesomeIcon className={styles.checkIcon} icon={faCheckCircle} />
              {t('signup.headers.subtitle')}
            </h2>
          </>
          <div className={cx('multiStepForm')}>
            <SinglePageForms
              validationSchemas={validationSchemas}
              onSubmit={onSubmit}
              initialValues={{
                firstName: params.firstName,
                lastName: params.lastName,
                email: params.email,
                password: '',
                company: params.companyName,
                slug: params.companyDomain
              }}
            >
              <PageContainer render={EmailFormPage} />
              <PageContainer render={NameFormPage} />
              <PageContainer render={PasswordFormPage} />
              {/* <PageContainer render={PhoneFormPage} /> */}
              <PageContainer render={SlugFormPage} />
            </SinglePageForms>
            <Disclaimer />
          </div>
        </div>
      </div>
    </>
  );
};

SignupForm.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
};

export default SignupForm;
