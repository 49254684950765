import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import FormInput from '../../FormInput';
import Error from '../Error';

export const SLUG_MAX_LENGTH = 24;
export const slugify = value => _.kebabCase(value)
  .toLowerCase().trim().substring(0, SLUG_MAX_LENGTH - 1);

const NameFormPage = ({ setFieldValue, status }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Field type="text" name="firstName" label={t('signup.nameForm.firstName')} component={FormInput} />
      <Field type="text" name="lastName" label={t('signup.nameForm.lastName')} component={FormInput} />
      <Field
        type="text"
        name="company"
        label={t('signup.nameForm.companyName')}
        component={FormInput}
        onChange={(e) => {
          const { value } = e.target;
          setFieldValue('company', value);
          setFieldValue('slug', slugify(value));
        }}
      />
      {status.error && (<Error status={status} />)}
    </>
  );
};

NameFormPage.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  status: PropTypes.shape({
    error: PropTypes.string
  })
};

NameFormPage.defaultProps = {
  status: {}
};

export default NameFormPage;
