/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from './Form';

const SinglePageForms = ({
  className,
  children,
  onSubmit,
  initialValues,
  validationSchemas
}) => {
  const handleSubmitForm = async (values, bag) => {
    try {
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'signup' });
      }
    } catch (e) { /** intentionally empty */ }
    await onSubmit({ ...values }, bag);
  };
  return (
    <Formik
      initialValues={initialValues}
      initialStatus={{}}
      enableReinitialize={false}
      validationSchema={validationSchemas}
      onSubmit={handleSubmitForm}
      displayName="SignupForm"
    >
      {props => (
        children.map((child, i) => (
          <Form
            key={`form-${i}`}
            activePage={child}
            className={className}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        ))
      )}
    </Formik>
  );
};

SinglePageForms.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  validationSchemas: PropTypes.shape({}).isRequired
};

SinglePageForms.defaultProps = {
  className: null,
  initialValues: {}
};

export default SinglePageForms;
