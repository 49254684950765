import bugsnag from '@bugsnag/browser';

const { appVersion, releaseStage } = window.bugsnag || {};

const options = {
  apiKey: '5f638ef6daceed1b15503c20e2dfdb45',
  appVersion,
  autoCaptureSessions: false,
  collectUserIp: false,
  notifyReleaseStages: ['dearlucy-staging-signup', 'dearlucy-production-signup'],
  releaseStage
};

export default bugsnag(options);
