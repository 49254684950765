import React from 'react';
import PropTypes from 'prop-types';
import logo from './dearlucy-logo-white.png';
import logoDark from './dearlucy-logo-transparent.png';
import styles from './styles.scss';

const Logo = ({ isColorLogo }) => (
  <a href="https://www.dearlucy.co/" className={styles.logo}>
    <img src={isColorLogo ? logoDark : logo} alt="Dear Lucy" />
  </a>
);

Logo.defaultProps = {
  isColorLogo: false
};

Logo.propTypes = {
  isColorLogo: PropTypes.bool
};

export default Logo;
