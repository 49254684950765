import bugsnagClient from './bugsnag-client'; // eslint-disable-line import/order
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.scss';
import './locale/i18n';
import App from './components/App';

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

const render = (AppComponent) => {
  ReactDOM.render(
    <ErrorBoundary>
      <AppComponent />
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    // eslint-disable-next-line
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}
