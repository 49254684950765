/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    lng: 'en',
    react: {
      useSuspense: false
    }
  });

i18n.addResourceBundle('en', 'common', en);

export default i18n;
