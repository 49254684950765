import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import styles from './styles.scss';
import LoginLink from './LoginLink';
import SignupForm from '../SignupForm';

const App = () => (
  <BrowserRouter>
    <div className={styles.app}>
      <div className={styles.header}>
        <LoginLink className={styles.login} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route path="/" component={SignupForm} />
        </Switch>
      </div>
    </div>
  </BrowserRouter>
);

export default App;
