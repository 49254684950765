import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import FormInput from '../../FormInput';
import Error from '../Error';

const EmailFormPage = ({ status }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Field type="email" name="email" label={t('signup.emailForm.email')} component={FormInput} focusOnRender />
      {status.error && (<Error status={status} />)}
    </>
  );
};

EmailFormPage.propTypes = {
  status: PropTypes.shape({
    error: PropTypes.string
  })
};

EmailFormPage.defaultProps = {
  status: {}
};

export default EmailFormPage;
