import React from 'react';
import { Trans } from 'react-i18next';
import styles from './styles.scss';

const Disclaimer = () => (
  <p className={styles.disclaimer}>
    <small>
      <Trans i18nKey="app.disclaimer">
        By signing up you agree to
        {' '}
        <a href="https://www.dearlucy.co/terms" target="_blank" rel="noopener noreferrer">General Terms</a>
        {' '}
        and
        {' '}
        <a href="https://www.dearlucy.co/privacy" target="_blank" rel="noopener noreferrer">Privacy&nbsp;Policy</a>
        .
      </Trans>
    </small>
  </p>
);

export default Disclaimer;
