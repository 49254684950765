/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';

const { loginAppUrl } = window.dearLucy;

interface LoginLinkProps {
  className: string;
}

const LoginLink: React.FC<LoginLinkProps> = ({ className }: { className: string }) => {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
  const location = useLocation();
  const query = location.pathname === '/pipedrive' ? '?source=pipedrive' : '';
  const loginUrl = `${loginAppUrl}${query}`;
  return (
    <div className={className}>
      <small>
        <Trans i18nKey="app.login">
          Already a customer?
          {' '}
          <a href={loginUrl}>Sign in</a>
        </Trans>
      </small>
    </div>
  );
};

export default LoginLink;
