import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import FormInput from '../../FormInput';
import Error from '../Error';

const PasswordFormPage = ({ status }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Field
        type="password"
        name="password"
        label={t('signup.passwordForm.password')}
        component={FormInput}
      />
      {status.error && (<Error status={status} />)}
    </>
  );
};

PasswordFormPage.propTypes = {
  status: PropTypes.shape({
    error: PropTypes.string
  })
};

PasswordFormPage.defaultProps = {
  status: {}
};

export default PasswordFormPage;
