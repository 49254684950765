import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from '../../FormInput';
import Error from '../Error';
import styles from './styles.scss';

const { frontEndBaseDomain } = window.dearLucy;

const SlugFormPage = ({ isSubmitting, status }) => {
  const { t } = useTranslation('common');
  return (
    <>
      <div className={styles.slug}>
        <span className="hide-on-mobile">https://</span>
        <Field
          type="text"
          name="slug"
          component={FormInput}
        />
        <span>{`.${frontEndBaseDomain}`}</span>
      </div>
      {status.error && (<Error status={status} />)}
      <button
        className="button button--primary submit-button"
        type="submit"
      >
        {!isSubmitting && t('signup.slugForm.submit')}
        {isSubmitting && (
          <span className={styles.progress}>
            <FontAwesomeIcon icon={faSpinner} size="lg" pulse />
            {t('signup.submitProgress')}
          </span>
        )}
      </button>
    </>
  );
};

SlugFormPage.defaultProps = {
  status: {}
};

SlugFormPage.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  status: PropTypes.shape({
    error: PropTypes.string,
    nextAvailableRequestInSeconds: PropTypes.number
  })
};

export default SlugFormPage;
