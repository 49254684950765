// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".app___3IZjA{display:flex;flex-direction:column;margin:0 auto;max-width:1200px;min-height:100vh;min-width:320px;padding:20px}.header___ar2Z-{align-items:center;display:flex;flex-direction:row-reverse;height:60px;justify-content:space-between;width:100%}.logo___2X5YN img{max-height:80px}.container___3M70d{align-items:center;display:flex;flex-direction:column;flex:1;justify-content:flex-start;padding-top:20px}@media (min-width: 768px){.container___3M70d{padding-top:0;justify-content:center}}\n", "",{"version":3,"sources":["/app/client/app/components/App/styles.scss"],"names":[],"mappings":"AAMA,aACE,YAAa,CACb,qBAAsB,CACtB,aAAc,CACd,gBARoB,CASpB,gBAAiB,CACjB,eAAgB,CAChB,YATgB,CAWjB,gBAGC,kBAAmB,CACnB,YAAa,CACb,0BAA2B,CAC3B,WAlBkB,CAmBlB,6BAA8B,CAC9B,UAAW,CACZ,kBAIG,eAAgB,CACjB,mBAID,kBAAmB,CACnB,YAAa,CACb,qBAAsB,CACtB,MAAO,CACP,0BAA2B,CAC3B,gBAAiB,CAEjB,0BARF,mBASI,aAAc,CACd,sBAAuB,CAE1B","file":"styles.scss","sourcesContent":["@import \"../../styles/variables\";\n\n$app-max-width: 1200px;\n$header-height: 60px;\n$app-padding: 20px;\n\n.app {\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n  max-width: $app-max-width;\n  min-height: 100vh;\n  min-width: 320px;\n  padding: $app-padding;\n\n}\n\n.header {\n  align-items: center;\n  display: flex;\n  flex-direction: row-reverse;\n  height: $header-height;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.logo {\n  img {\n    max-height: 80px;\n  }\n}\n\n.container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  justify-content: flex-start;\n  padding-top: 20px;\n\n  @media #{$tablet-and-up} {\n    padding-top: 0;\n    justify-content: center;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"app": "app___3IZjA",
	"header": "header___ar2Z-",
	"logo": "logo___2X5YN",
	"container": "container___3M70d"
};
module.exports = exports;
