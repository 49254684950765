// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".slug___3BI11{align-items:center;color:rgba(255,255,255,0.8);display:flex !important;font-weight:bold;display:none !important}.slug___3BI11>span{font-size:20px}.slug___3BI11>div{flex:2;margin:0 10px}.slug___3BI11 input{font-size:20px;line-height:50px;padding-top:0 !important;text-align:center}.progress___2M7E6 svg{margin-right:10px}\n", "",{"version":3,"sources":["/app/client/app/components/SignupForm/Forms/styles.scss"],"names":[],"mappings":"AAEA,cACE,kBAAmB,CACnB,2BAAiB,CACjB,uBAAwB,CACxB,gBAAiB,CACjB,uBAAuB,CALzB,mBAQI,cAAe,CARnB,kBAYI,MAAO,CACP,aAAc,CAblB,oBAiBI,cAAe,CACf,gBAAiB,CACjB,wBAAyB,CACzB,iBAAkB,CACnB,sBAKC,iBAAkB","file":"styles.scss","sourcesContent":["@import \"../../../styles/variables\";\n\n.slug {\n  align-items: center;\n  color: rgba(white, 0.8);\n  display: flex !important;\n  font-weight: bold;\n  display: none!important;\n\n  > span {\n    font-size: 20px;\n  }\n\n  > div {\n    flex: 2;\n    margin: 0 10px;\n  }\n\n  input {\n    font-size: 20px;\n    line-height: 50px;\n    padding-top: 0 !important;\n    text-align: center;\n  }\n}\n\n.progress {\n  svg {\n    margin-right: 10px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"slug": "slug___3BI11",
	"progress": "progress___2M7E6"
};
module.exports = exports;
